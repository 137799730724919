<template>
    <div class="box">
		<h4 class="m-0">
			{{ $t('intra_location.intra_location') }}
			<font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
		</h4>
		<div class="row align-items-center" id="intra_location">
			<div v-if="intra_location" class="col">
				<i>{{ intra_location.intralocation_label }}</i>
			</div>
			<div v-else-if="horseFicheReadOnly" class="col">
				{{ $t("intra_location.need_upgrade_update_location") }}
			</div>
			<!-- le cheval est sur une résidence principale, mais n'a pas de déplacement interne récent (depuis la dernière entrée sur une résidence principale) -->
			<div v-else-if="last_enter_resid" class="col">
				{{ $t("intra_location.no_entry") }}
			</div>
			<!-- le cheval n'est pas sur une résidence principale actuellement -->
			<div v-else class="col">
				{{ $t("intra_location.not_in_resid") }}
			</div>

			<div v-if="!horseFicheReadOnly && !processing" class="col-auto">
				<!-- Si on a déjà eu un mouvement d'entrée, on propose l'ajout d'une localisation interne -->
				<button v-if="last_enter_resid"
						class="btn btn-link"  
						v-on:click.stop.prevent="changeIntraLocation()"
					>
					<span class="d-none d-md-inline">{{ $t('intra_location.change_intra_location') }}</span> <font-awesome-icon class="ml-2" :icon="['fal', 'long-arrow-right']" />
				</button>
			
				<!-- Si pas de mouvement d'entrée initiale enregistrée pour ce cheval, on redirige vers AjoutMouvement -->
				<router-link v-else :to="{ name: 'mouvementAjout', params: { horse_id: horse_id, from: 'HorseFicheMouvements', first_mouv: true }}">
					<span class="d-none d-md-inline">{{ $t('intra_location.set_intra_location') }}</span> <font-awesome-icon class="ml-2" :icon="['fal', 'long-arrow-right']" />
				</router-link>
			</div>
		</div>
 
        <MoveIntraLocationModal ref="modalIntraLocation" :residence_id="actualResidId" @complete="saveMove" />
    </div>
</template>



<script type="text/javascript">
    import { EventBus } from 'EventBus';
    import Horse from "@/mixins/Horse.js";
    import IntraLocation from "@/mixins/IntraLocation.js";
    import Mouvements from "@/mixins/Mouvements.js";

	export default {
		name: "historiqueMouvements",
		mixins: [Horse, IntraLocation, Mouvements],
        props: [
            'horse_id',
        ],
        components: {
			MoveIntraLocationModal: () => import('@/components/Mouvements/ModalMoveIntraLocation')
        },
		data () {
			return {
				intra_location: null,
				last_enter_resid: null,
				processing: false
			}
		},
		created() {
			this.preinit_component()
		},
		methods: {
			preinit_component() {
				this.getHorseIntraLocation(this.horse_id)
					.then(res => (this.intra_location  = res ? res.mouvement_intra_location : null))

				this.getHorseLastResidence(this.horse_id)
					.then(res => {
						if(res) this.last_enter_resid = res[0]
					})
			},

			refresh() {
				this.preinit_component()
			},

            changeIntraLocation() {
                this.$refs.modalIntraLocation.openModal()
            },
           
            async saveMove(data) {
                const result = {
					type: 'intra_location',
					mouvement_label   : data.intra_location.intralocation_label,
					mouvement_date    : data.mouvement_date,
					mouvement_raison  : data.intra_location.raison,
					mouvement_cp      : this.last_enter_resid.mouvement_cp,
					mouvement_ville   : this.last_enter_resid.mouvement_ville,
					mouvement_adresse : this.last_enter_resid.mouvement_adresse,
					mouvement_intra_location: data.intra_location.intralocation_id, 
				}
				
                await this.processMouvOperations([result], [this.horse_id])
				
				// Refresh affichage
				this.preinit_component()
                EventBus.$emit('HistoriqueMouvement::updateMouvements')
            }
		},
        computed: {
            horseFicheReadOnly() {
                return this.$store.state.horseFicheReadonly
			},
			actualResidId() {
				return this.last_enter_resid ? this.last_enter_resid.mouvement_lieu : 0
			}
        }
	}
</script>
